/**
 *
 *   DONT IMPORT ANYTHING
 *
 *
 *  */
// In order to define languages in one place see link
// https://stackoverflow.com/a/45486495
const allLangs = ["el", "en"] as const

export const availableLangs = [...allLangs]
