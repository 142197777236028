import { availableLangs } from "locales"

export type Translate = (key: string) => string

export type Locale = typeof availableLangs[number]

export type TranslatableItem<T = string> = { [K in Locale]: T }

export const generateAlLangsInfo = (t: Translate) =>
  availableLangs.map((code) => ({
    code,
    label: t(`int.${code}`) || code
  }))

export const initTranslatableItem = availableLangs.reduce((acc, lang) => {
  return { ...acc, [lang]: "" }
}, {}) as TranslatableItem

export interface Translations {
  [key: string]: TranslatableItem
}

export type AvailableLangs = Array<Locale>

export { availableLangs }
