import React, { useState } from "react"
import { css, cx } from "emotion"
import Button from "components/Button"
import AuthWrapper from "components/AuthWrapper"

const container = css`
  background-color: #f8f9fa;
`
const deleteBox = css`
  background-color: rgba(220, 53, 69, 0.1);
`
export enum fileType {
  pdf = "pdf",
  doc = "doc",
  docx = "docx",
  xls = "xls"
}

export type Props = {
  type: fileType
  name: string
  fileName: string
  description?: string
  size: string
  className?: string
  canDownload?: boolean
  onDownload?: () => void
  onDelete?: () => void
  hideDelete?: boolean
}

function chooseFileImg(type: fileType) {
  switch (type) {
    case "pdf":
      return "pdf"
    case "doc":
    case "docx":
      return "doc"
    case "xls":
      return "xls"

    default:
      return "pdf"
  }
}

function FileCard(props: Props) {
  const [deleteFile, setDeleteFile] = useState(false)
  const background = deleteFile ? deleteBox : container
  return (
    <div
      className={cx(
        props.className,
        background,
        "w-full items-center flex justify-between h-21 py-5 rounded-md"
      )}>
      {deleteFile ? (
        <div className={cx("w-full items-center py-5 px-5 flex")}>
          <div>
            <strong className="text-base">Διαγραφή Αρχείου!</strong>
            <div className="text-smxs text-default-500">
              Είστε σίγουρος ότι θέλετε να διαγράψετε το αρχείο;
            </div>
          </div>
          <Button
            onClick={() => setDeleteFile(false)}
            type="light-danger"
            variant="contained"
            className="ml-auto mr-4"
            small>
            Ακύρωση
          </Button>

          <Button onClick={props.onDelete} small type="danger">
            Διαγραφή
          </Button>
        </div>
      ) : (
        <>
          <div className="w-full flex flex-col pl-5">
            <strong className="text-base">{props.name}</strong>
            <span className="text-smxs text-default-500">
              {props.description}
            </span>
          </div>
          <div className="flex cursor-pointer select-none pr-5">
            <div
              onClick={props.onDownload}
              className="bg-white items-center py-2 border-gray-300 rounded-sm border border-3 px-6 h-full w-64 flex justify-between">
              <img src={`/images/${chooseFileImg(props.type)}.svg`} alt="" />
              <span className="text-xs max-w-30 truncate ml-2 mr-3 ">
                {props.fileName}
              </span>
              <span className="text-xs text-gray-500 max-w-40 truncate">
                {props.size}
              </span>
            </div>
            <AuthWrapper reqRoles={["SPONSOR"]}>
              <img
                onClick={props.onDownload}
                src="/images/download.svg"
                alt="download"
                className="ml-2.5 cursor-pointer"
              />
            </AuthWrapper>
            {!props.hideDelete && (
              <AuthWrapper reqRoles={["ADMIN", "MINISTRY"]}>
                <img
                  src="/images/red.svg"
                  alt="delete"
                  className="ml-2.5 cursor-pointer"
                  onClick={() => setDeleteFile(true)}
                />
              </AuthWrapper>
            )}
          </div>
        </>
      )}
    </div>
  )
}
export default FileCard
