import React from "react"
import { css, cx, keyframes } from "emotion"

const circularRotate = keyframes`
    100% {
      transform: rotate(360deg);
    }
  `

const circularDash = keyframes`
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
`

const wrapper = css`
  animation: ${circularRotate} 1.4s linear infinite;
`

const circleCls = css`
  animation: ${circularDash} 1.4s ease-in-out infinite;
  stroke-dasharray: 80px, 200px;
  stroke-dashoffset: 0;
  stroke: currentColor;
`

interface IProps {
  className?: string
  size?: number
  color?: string
}

const LoadingSpinner: React.FC<IProps> = ({
  className,
  size = 20,
  color = "text-primary"
}) => (
  <div
    className={cx(wrapper, className, color)}
    style={{ width: size, height: size }}>
    <svg viewBox="22 22 44 44">
      <circle
        className={circleCls}
        cx="44"
        cy="44"
        r="20.2"
        fill="none"
        strokeWidth="3.6"
      />
    </svg>
  </div>
)

export default LoadingSpinner
