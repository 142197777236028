import { ComponentType, lazy } from "react"

const retry = <T extends ComponentType<any>>(
  fn: () => Promise<{ default: T }>,
  retriesLeft = 5,
  interval = 1000
) => {
  return new Promise<{ default: T }>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: any) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error)
            return
          }

          // Passing on "reject" is the important part
          retry(fn, interval, retriesLeft - 1).then(resolve, reject)
        }, interval)
      })
  })
}

export default <T extends ComponentType<any>>(
  fn: () => Promise<{ default: T }>
) => lazy(() => retry(fn))
