import React, { useContext, useState, useCallback } from "react"
import { IAuth } from "./types"
import perms from "utils/perms"
import * as R from "ramda"
import jwtDecode from "jwt-decode"

export const initAuth: IAuth = {
  access_token: "",
  expires_in: 0,
  tokenExp: 0,
  name: "",
  status: "ACTIVE",
  authorities: [],
  employed_at: undefined,
  roles: [],
  firstTime: true,
  given_name: "",
  preferred_username: "",
  family_name: ""
}

export const LOGIN_URL = "login"

export const LOCAL_STORAGE_AUTH_KEY = "sponsors_auth"

type IAuthContext = {
  auth: IAuth
  updateAuth: (e: IAuth) => void
  deleteAuth: () => void
}

const AuthContext = React.createContext<IAuthContext>({
  auth: initAuth,
  updateAuth: () => void 0,
  deleteAuth: () => void 0
})

function rolesToAuthorities(roles: string[]) {
  const authorities = roles.reduce((acc, curr) => {
    const permArr = perms[curr] || [""]
    return [...acc, ...permArr]
  }, [] as string[])
  return R.uniq(authorities)
}

export const getAccountInfos = (access_token: string): IAuth => {
  try {
    const tokenInfos = jwtDecode<any>(access_token)

    const roles = tokenInfos.realm_access.roles
    const authorities = rolesToAuthorities(roles)
    const tokenExp = tokenInfos.exp * 1000
    const name = tokenInfos.name
    const employed_at = tokenInfos.employed_at
    const sponsor_type = tokenInfos.sponsor_type
    const firstTime = tokenInfos.firstTime === "true" ? true : false
    const status = tokenInfos.status
    const given_name = tokenInfos.given_name
    const preferred_username = tokenInfos.preferred_username
    const family_name = tokenInfos.family_name

    return {
      access_token,
      tokenExp,
      expires_in: tokenInfos.iat - tokenInfos.eat,
      employed_at,
      authorities,
      firstTime,
      name,
      roles,
      sponsor_type,
      status,
      given_name,
      preferred_username,
      family_name
    }
  } catch {
    return initAuth
  }
}

export function storePersistAuth(auth: IAuth) {
  localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, JSON.stringify(auth))
}

export function removePersistAuth() {
  localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY)
}

function getPersistAuth() {
  const sponsors_auth = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY)
  return sponsors_auth ? JSON.parse(sponsors_auth) : initAuth
}

type Props = {
  children: React.ReactNode
}

function AuthProvider(props: Props) {
  const [auth, setAuth] = useState(getPersistAuth())

  const updateAuth = useCallback((_auth) => {
    storePersistAuth(_auth)
    setAuth(_auth)
  }, [])

  const deleteAuth = useCallback(() => {
    removePersistAuth()
    setAuth(initAuth)
  }, [])

  return (
    <AuthContext.Provider value={{ auth, updateAuth, deleteAuth }}>
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthProvider

export const useAuth = () => useContext(AuthContext)
