export const loadFromStorage = (key: string, curr_ver = "v1") => {
  try {
    const serializedState = localStorage.getItem(`${key}_${curr_ver}`)
    if (serializedState === null) return undefined
    return JSON.parse(serializedState)
  } catch (e) {
    return undefined
  }
}

export const saveToStorage = (
  key: string,
  objectToSave: object,
  curr_ver = "v1"
) => {
  try {
    const serializedState = JSON.stringify(objectToSave)
    localStorage.setItem(`${key}_${curr_ver}`, serializedState)
  } catch (e) {} //eslint-disable-line
}
