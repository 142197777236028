import React from "react"
import { Redirect } from "react-router-dom"
import * as R from "ramda"
import { useAuth } from "providers/Auth"

const getRole = (roles?: string[]) =>
  roles?.includes("ADMIN")
    ? "ADMIN"
    : roles?.includes("SPONSOR")
    ? "SPONSOR"
    : "MINISTRY"

export function useRole() {
  const { roles } = useAuth().auth
  return getRole(roles)
}

export type ReqRole = "ADMIN" | "SPONSOR" | "MINISTRY"

interface IProps {
  reqRoles?: ReqRole[]
  reqPerm?: Array<string>
  redirect?: boolean
  children: React.ReactElement
  redirectTo?: string
}

const AuthWrapper: React.FC<IProps> = (props) => {
  const { authorities } = useAuth().auth
  const { reqRoles, reqPerm, redirect } = props
  const role = useRole()
  const hasRole = reqRoles ? reqRoles.includes(role) : true

  const hasPerm = reqPerm
    ? R.intersection(reqPerm, authorities).length > 0
    : true
  if (hasPerm && hasRole) return props.children
  else if (redirect) {
    return <Redirect to={{ pathname: props.redirectTo || "/not-found" }} />
  } else return null
}

export default AuthWrapper
