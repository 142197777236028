export const admin = [
  "actions:read_owner",
  "actions:read_all",
  "actions:create_owner",
  "actions:create_all",
  "actions:edit_owner",
  "actions:edit_all",
  "actions:delete_owner",
  "actions:delete_all",
  "sponsorships:read_owner",
  "sponsorships:read_all",
  "sponsorships:create_owner",
  "sponsorships:create_all",
  "sponsorships:edit_owner",
  "sponsorships:edit_all",
  "sponsorships:delete_owner",
  "sponsorships:delete_all",
  "ministries:read",
  "ministries:create",
  "ministries:edit",
  "ministries:delete",
  "sponsors:read",
  "sponsors:create",
  "sponsors:edit",
  "sponsors:delete"
]

export const facilitator = [
  "actions:read_owner",
  "actions:read_all",
  "actions:create_owner",
  "actions:create_all",
  "actions:edit_owner",
  "actions:edit_all",
  "actions:delete_owner",
  "actions:delete_all",
  "sponsorships:read_owner",
  "sponsorships:read_all",
  "sponsorships:create_owner",
  "sponsorships:create_all",
  "sponsorships:edit_owner",
  "sponsorships:edit_all",
  "sponsorships:delete_owner",
  "sponsorships:delete_all",
  "ministries:read",
  "ministries:create",
  "ministries:edit",
  "ministries:delete",
  "sponsors:read",
  "sponsors:create",
  "sponsors:edit",
  "sponsors:delete"
]

export const ministry_user = [
  "actions:read_owner",
  "actions:read_all",
  "actions:create_owner",
  "actions:edit_owner",
  "actions:delete_owner",
  "sponsorships:read_owner",
  "sponsorships:read_all",
  "sponsorships:edit_owner",
  "sponsorships:delete_owner",
  "ministries:read",
  "sponsors:read"
]

export const sponsor_user = [
  "actions:read_owner",
  "actions:read_all",
  "sponsorships:read_owner",
  "sponsorships:read_all",
  "sponsorships:create_owner",
  "sponsorships:edit_owner",
  "sponsorships:delete_owner",
  "ministries:read",
  "sponsors:read"
]

export default {
  admin,
  facilitator,
  ministry: ministry_user,
  sponsor: sponsor_user
} as Record<string, string[]>
