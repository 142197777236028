import { Translations } from "./types"
import { sleep } from "utils"

// const APP_ID = "glp_admin_panel_keys"
// const URL = `/api/bo/translation/${APP_ID}`

export async function initI18n() {
  // const url = `${URL}/all`
  //   const response = await fetch(url, {
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded"
  //     }
  //   })
  //   return response.json()
  await sleep(3000)
  return {}
}

export async function updateI18nKeys(translations: Translations) {
  // const url = `${URL}/all`
  // const body = JSON.stringify(translations)
  //   const response = await authFetch(url, { method: "PUT", body })
  //   return response.json()
  await sleep(3000)
  return {}
}
