import { formatDistanceToNow } from "date-fns"
import { Translate } from "I18n"
import { fileType } from "components/FileCard"
import { categoryResource } from "api/actions"
import sanitize from "sanitize-html"

export type BackgroundTypes =
  | "default"
  | "success"
  | "primary"
  | "secondary"
  | "danger"
  | "inactive"

export const BACKGROUNDS: Record<BackgroundTypes, string> = {
  default: "bg-default-200",
  success: "bg-success",
  primary: "bg-primary",
  secondary: "bg-secondary",
  danger: "bg-danger",
  inactive: "bg-default-500"
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const daysFromNow = (date: number | Date, t: Translate) => {
  const distance = formatDistanceToNow(date).split(" ")
  const translated = distance.map((item) =>
    isNaN(Number(item)) ? t(`int.${item}`) : item
  )
  return translated.join(" ")
}

export const animateZoom =
  "transition ease-linear duration-150 transform scale-100 hover:scale-102 hover:shadow-xl"

export const parseNumber = (string: string) => Number(string.replace(/,/g, "."))

export const formatAmount = (amount: number) => {
  const formatted = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR"
  }).format(amount)
  return formatted
}

export const sanitizedHtml = (value?: string) =>
  value
    ? sanitize(value, {
        allowedTags: false,
        allowedAttributes: {
          "*": ["style"]
        }
      })
    : ""

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes"

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

const docTypes = ["docx", "doc"]
const xlTypes = ["xls", "xlsx"]

export const getFileTypeByName = (name: string): fileType => {
  const [, suffix] = name.split(".")
  if (docTypes.includes(suffix)) return fileType.doc
  if (xlTypes.includes(suffix)) return fileType.xls
  return fileType.pdf
}

export const mapFileType: Record<string, fileType> = {
  "application/pdf": fileType.pdf,
  "application/vnd.ms-excel": fileType.xls,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    fileType.xls,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    fileType.doc,

  "application/msword": fileType.doc
}

export function splitArrayAt<T>(index: number, array?: T[]): T[][] {
  return [array?.slice(0, index) || [], array?.slice(index) || []]
}

export function splitTags(array?: categoryResource[]) {
  const sorted = array?.sort((a, b) => {
    const tagALength = (tagCode2Name[a.name] || a.name).length
    const tagBLength = (tagCode2Name[b.name] || b.name).length
    return tagALength - tagBLength
  })
  let [firstTags, restTags] = splitArrayAt<categoryResource>(3, sorted)
  if (firstTags[2] && tagCode2Name[firstTags[2].name].length > 10) {
    return [
      [firstTags[0], firstTags[1]],
      [firstTags[2], ...restTags]
    ]
  }
  const gt10 = restTags.filter((tag) => tagCode2Name[tag.name].length > 10)
  const st10 = restTags.filter((tag) => tagCode2Name[tag.name].length <= 10)
  const [firstSmall, restSmall] = splitArrayAt<categoryResource>(3, st10)
  return [
    [...firstTags, ...firstSmall],
    [...restSmall, ...gt10]
  ]
}

export const tagGroup2Type = {
  main: "secondary",
  projectType: "success",
  urgency: "attention"
}

export const actionFileTypes = [
  {
    label: "Περίγραμμα Δράσης",
    value: "ACTION_OUTLINE"
  },
  {
    label: "Αίτημα κάλυψης ανάγκης",
    value: "ACTION_DEMAND_COVERAGE"
  },
  {
    label: "'Αλλο",
    value: "ACTION_OTHER"
  }
]

export const sponsorshipFileTypes = [
  {
    label: "Επιστολή χορηγίας",
    value: "SPONSORSHIP_LETTER"
  },
  {
    label: "Αποδοχή δωρεάς",
    value: "SPONSORSHIP_DONATION_ACCEPTANCE"
  },
  {
    label: "Μνημόνιο συνεργασίας",
    value: "SPONSORSHIP_COOPERATION_MEMORANDUM"
  },
  {
    label: "Συμφωνητικό",
    value: "SPONSORSHIP_AGREEMENT"
  },
  {
    label: "Ολοκλήρωση παραλαβής δωρεάς",
    value: "SPONSORSHIP_COMPLETION"
  },
  {
    label: "'Αλλο",
    value: "SPONSORSHIP_OTHER"
  }
]

export const statuses = [
  {
    label: "Προσχέδιο",
    value: "ACTION_DRAFT"
  },
  {
    label: "Ενεργή",
    value: "ACTION_ACTIVE"
  },
  {
    label: "Ανενεργή",
    value: "ACTION_INACTIVE"
  }
]

export const regions = [
  { label: "Επικράτεια", value: "STATE" },
  {
    label: "Νήσοι Αιγαίου Πελάγους",
    value: "AEGEAN_ISLANDS"
  },
  {
    label: "Αττική",
    value: "ATTICA"
  },
  {
    label: "Στερεά Ελλάδα",
    value: "CENTRAL_GREECE"
  },
  {
    label: "Κρήτη",
    value: "CRETE"
  },
  {
    label: "Ηπειρος",
    value: "EPIRUS"
  },
  {
    label: "Νήσοι Ιονίου Πελάγους",
    value: "IONIAN_ISLANDS"
  },
  {
    label: "Μακεδονία",
    value: "MACEDONIA"
  },
  {
    label: "Πελοπόννησος",
    value: "PELOPONNESE"
  },
  {
    label: "Θεσσαλία",
    value: "THESSALY"
  },
  {
    label: "Θράκη",
    value: "THRACE"
  }
]

export const regionsOfInterest = [
  { label: "Επικράτεια", value: "STATE" },
  {
    label: "Βόρεια Εύβοια",
    value: "NORTHERN_EVIA"
  },
  {
    label: "Αττική",
    value: "ATTICA"
  },
  {
    label: "Τατόι",
    value: "TATOI"
  },
  {
    label: "Ηλεία",
    value: "ILIA"
  },
  {
    label: "Αρκαδία",
    value: "ARCADIA"
  },
  {
    label: "Μεσσηνία",
    value: "MESSINIA"
  },
  {
    label: "Λακωνία",
    value: "LAKONIA"
  },
  {
    label: "Φωκίδα",
    value: "FOKIDA"
  },
  {
    label: "Αχαΐα",
    value: "ACHAIA"
  },
  {
    label: "Κορινθία",
    value: "CORINTHIA"
  }
]

export const tagCode2Name: Record<string, string> = {
  environment: "Περιβάλλον",
  health: "Υγεία",
  rural_development: "Αγροτική Ανάπτυξη",
  political_protection: "Πολιτική Προστασία",
  education: "Εκπαίδευση",
  infrastructures: "Υποδομές",
  digital_transformati: "Ψηφιακός Μετασχηματισμός",
  development: "Χωρική Ανάπτυξη",
  flood_defences: "Αντιπλημμυρικά",
  culture: "Πολιτισμός",
  citizen_protection: "Προστασία του Πολίτη",
  projects: "Έργα",
  building_constructio: "Ανέγερση κτιριακών υποδομών",
  buying_building_infr: "Αγορά κτιριακών υποδομών",
  home_furnishing: "Οικιακός εξοπλισμός",
  individual_supplies: "Ατομικά εφόδια",
  restoration_building: "Αποκατάσταση κτιριακών υποδομών",
  supplies: "Προμήθειες",
  material_and_technic: "Υλικοτεχνικός εξοπλισμός",
  equipment: "Εξοπλισμός",
  clothing: "Ένδυση",
  pc_consumable: "Η/Υ – Αναλώσιμα",
  vehicles: "Οχήματα",
  services: "Υπηρεσίες",
  digital_upgrade: "Ψηφιακή αναβάθμιση",
  training: "Εκπαίδευση – Κατάρτιση προσωπικού",
  works: "Εργασίες",
  high_value_movables_: "Συντήρηση κινητών αντικειμένων μεγάλης αξίας",
  studies: "Μελέτες",
  promotion_communicat: "Προώθηση – Επικοινωνία",
  humanitarian_aid: "Ανθρωπιστική βοήθεια​",
  other: "Άλλο",
  short_term: "Άμεση: 1 – 3 μήνες",
  medium_term: "Μεσοπρόθεσμη: 4 – 12 μήνες",
  long_term: "Μακροπρόθεσμη: >1 έτος",

  transportation: "Μεταφορές",
  urban_env: "Αστικό Περιβάλλον",
  fire_protection: "Πυροπροστασία",
  trash_waste: "Απορρίματα - Απόβλητα",
  telco: "Τηλεπικοινωνίες",
  wood_logging: "Υλοτομήσεις",

  scholarships: "Υποτροφίες",
  national_defense: "Εθνική Άμυνα",
  social_solidarity: "Κοινωνική Αλληλεγγύη",
  gender_equality: "Ισότητα Φύλων",
  work: "Εργασία",
  tourism: "Τουρισμός",
  athletics: "Αθλητισμός"
}

export const ministryCodeToName: Record<string, string> = {
  finance: "Υπ. Οικονομικών",
  national_defence: "Υπ. Εθνικής Άμυνας",
  education_and_religious: "Υπ. Παιδείας & Θρησκευμάτων",
  labor_and_social_affairs: "Υπ. Εργασίας & Κοινωνικών Υποθέσεων",
  health: "Υπ. Υγείας",
  environment_and_energy: "Υπ.  Περιβάλλοντος & Ενέργειας",
  culture_and_sports: "Υπ. Πολιτισμού & Αθλητισμού",
  justice: "Υπ. Δικαιοσύνης",
  interior: "Υπ. Εσωτερικών",
  digital_governance: "Υπ. Ψηφιακής Διακυβέρνησης",
  infrastructure_and_transport: "Υπ. Υποδομών & Μεταφορών",
  shipping_and_island_policy: "Υπ. Ναυτιλίας & Νησιωτικής Πολιτικής",
  agricultural_development_and_food: "Υπ. Αγροτικής Ανάπτυξης & Τροφίμων",
  tourism: "Υπ. Τουρισμού",
  foreign_affairs: "Υπ. Εξωτερικών",
  development_and_investment: "Υπ. Ανάπτυξης & Επενδύσεων",
  citizen_protection: "Υπ. Προστασίας του Πολίτη",
  cabinet_of_greece: "Υπουργικο Συμβούλιο",
  migration_and_asylum: "Υπ. Μετανάστευσης & Ασύλου",
  restoration_mechanism: "Μηχανισμός Κρατικής Αρωγής"
}

export const ministryCodeToFullName: Record<string, string> = {
  finance: "Υπουργείο Οικονομικών",
  national_defence: "Υπουργείο Εθνικής Άμυνας",
  education_and_religious: "Υπουργείο Παιδείας & Θρησκευμάτων",
  labor_and_social_affairs: "Υπουργείο Εργασίας & Κοινωνικών Υποθέσεων",
  health: "Υπουργείο Υγείας",
  environment_and_energy: "Υπουργείο  Περιβάλλοντος & Ενέργειας",
  culture_and_sports: "Υπουργείο Πολιτισμού & Αθλητισμού",
  justice: "Υπουργείο Δικαιοσύνης",
  interior: "Υπουργείο Εσωτερικών",
  digital_governance: "Υπουργείο Ψηφιακής Διακυβέρνησης",
  infrastructure_and_transport: "Υπουργείο Υποδομών & Μεταφορών",
  shipping_and_island_policy: "Υπουργείο Ναυτιλίας & Νησιωτικής Πολιτικής",
  agricultural_development_and_food: "Υπουργείο Αγροτικής Ανάπτυξης & Τροφίμων",
  tourism: "Υπουργείο Τουρισμού",
  foreign_affairs: "Υπουργείο Εξωτερικών",
  development_and_investment: "Υπουργείο Ανάπτυξης & Επενδύσεων",
  citizen_protection: "Υπουργείο Προστασίας του Πολίτη",
  cabinet_of_greece: "Υπουργικο Συμβούλιο",
  migration_and_asylum: "Υπουργείο Μετανάστευσης & Ασύλου",
  restoration_mechanism: "Μηχανισμός Κρατικής Αρωγής"
}

export const ministriesToShortName: Record<string, string> = {
  finance_short_name: "ΥΠ.ΟΙΚ.",
  national_defence_short_name: "ΥΠ.ΕΘ.Α.",
  education_and_religious_short_name: "Υ.ΠΑΙ.Θ.",
  labor_and_social_affairs_short_name: "ΥΠ.Ε.Κ.ΥΠ.",
  health_short_name: "ΥΠ.ΥΓ.",
  environment_and_energy_short_name: "Υ.Π.ΕΝ.",
  culture_and_sports_short_name: "ΥΠ.ΠΟ.Α.",
  justice_short_name: "ΥΠ.ΔΙΚ.",
  interior_short_name: "ΥΠ.ΕΣ.",
  digital_governance_short_name: "Υ.Ψ.Δ.",
  infrastructure_and_transport_short_name: "ΥΠ.Υ.ΜΕ.",
  shipping_and_island_policy_short_name: "Υ.ΝΑ.Ν.Π.",
  agricultural_development_and_food_short_name: "ΥΠ.Α.Α.Τ.",
  tourism_short_name: "ΥΠ.ΤΟ.",
  foreign_affairs_short_name: "ΥΠ.ΕΞ.",
  development_and_investment_short_name: "ΥΠ.ΑΝ.Ε.",
  citizen_protection_short_name: "Υ.Π.Τ.Π.",
  cabinet_of_greece_short_name: "Υ.Σ.",
  migration_and_asylum_short_name: "Υ.ΜΕΤ.Α.",
  restoration_mechanism_short_name: "ΜΗΧ.Κ.Α."
}

export const sectorCode2NameData: Record<string, Record<string, string>> = {
  citizen_protection: {
    counter_criminality_policy: "ΓΓ. Αντιεγκληματικής Πολιτικής",
    civil_protection: "ΓΓ. Πολιτικής Προστασίας",
    fire_department: "Πυροσβεστικό Σώμα",
    police: "Ελληνική Αστυνομία",
    public_order: "ΓΓ. Δημοσίας Τάξης",
    main_department: "Κεντρική Υπηρεσία Υπουργείου"
  },
  infrastructure_and_transport: {
    main_department: "Κεντρική Υπηρεσία Υπουργείου",
    transportations: "ΓΓ. Μεταφορών",
    infrastractures: "ΓΓ. Υποδομών"
  },
  interior: {
    internal_affairs: "ΓΓ. Εσωτερικών και Οργάνωσης",
    immigration: "ΓΓ. Ιθαγένειας",
    hr: "ΓΓ. Ανθρώπινου Δυναμικού Δημοσίου Τομέα",
    main_department: "Κεντρική Υπηρεσία Υπουργείου"
  },
  labor_and_social_affairs: {
    social_security: "ΓΓ. Κοινωνικών Ασφαλίσεων",
    solidarity: "ΓΓ. Κοινωνικής Αλληλεγγύης και Καταπολέμησης της Φτώχειας",
    main_department: "Κεντρική Υπηρεσία Υπουργείου",
    employment: "ΓΓ.  Εργασίας",
    equality:
      "ΓΓ. Δημογραφικής και Οικογενειακής Πολιτικής και Ισότητας των Φύλων"
  },
  tourism: {
    main_department: "Κεντρική Υπηρεσία Υπουργείου",
    eot: "Ε.Ο.Τ",
    tourism_policy: "ΓΓ. Τουριστικής Πολιτικής και Ανάπτυξης"
  },
  national_defence: {
    ges: "ΓΕΣ",
    gea: "ΓΕΑ",
    gen: "ΓΕΝ",
    geetha: "ΓΕΕΘΑ",
    national_defence: "ΓΓ. Υπουργείου Εθνικής 'Αμυνας",
    main_department: "Κεντρική Υπηρεσία Υπουργείου"
  },
  restoration_mechanism: {
    local_gov: "Τοπική Αυτοδιοίκηση",
    gov_presidency: "Προεδρία της Κυβέρνησης",
    struc_and_transport: "Υπ. Υποδομών & Μεταφορών",
    environmental: "Υπ. Περιβάλλοντος και Ενέργειας",
    digigov: "Υπ. Ψηφιακής Διακυβέρνησης",
    safety: "Υπ. Προστασίας του Πολίτη",
    education: "Υπ. Παιδείας",
    civil_protection: "Υπ. Πολιτικής Προστασίας",
    interior: "Υπ. Εσωτερικών",
    culture_and_sports: "Υπ. Πολιτισμού",
    undersecretary_to_prime: "Υφυπουργός στον Πρωθυπουργό"
  },
  shipping_and_island_policy: {
    main_department: "Κεντρική Υπηρεσία Υπουργείου",
    island_policy: "ΓΓ. Αιγαίου και Νησιωτικής Πολιτικής",
    naval_policy: "ΓΓ. Λιμένων, Λιμενικής Πολιτικής & Ναυτιλιακών Επενδύσεων"
  },
  health: {
    health_departments: "ΓΓ. Υπηρεσιών Υγείας",
    national_health: "ΓΓ. Δημόσιας Υγείας, Πρόληψης και Εθελοντισμού",
    main_department: "Κεντρική Υπηρεσία Υπουργείου",
    healthcare: "Γ.Γ Πρωτοβάθμιας Φροντίδας"
  },
  culture_and_sports: {
    main_department: "Κεντρική Υπηρεσία Υπουργείου",
    modern_culture: "ΓΓ. Σύγχρονου Πολιτισμού",
    sports: "ΓΓ. Αθλητισμού",
    culture: "ΓΓ. Πολιτισμού"
  },
  education_and_religious: {
    religion: "ΓΓ. Θρησκευμάτων",
    professional_education:
      "ΓΓ Επαγγελματικής Εκπαίδευσης, Κατάρτισης και Διά Βίου Μάθησης",
    supreme_education: "ΓΓ. Ανώτατης Εκπαίδευσης",
    education:
      "ΓΓ. Πρωτοβάθμιας, Δευτεροβάθμιας Εκπαίδευσης και Ειδικής Αγωγής",
    main_department: "Κεντρική Υπηρεσία Υπουργείου"
  },
  finance: {
    fiscal_policy: "ΓΓ. Δημοσιονομικής Πολιτικής",
    private_debt: "ΕΓ. Διαχείρισης Ιδιωτικού Χρέους",
    main_department: "Κεντρική Υπηρεσία Υπουργείου",
    tax_policy: "ΓΓ. Φορολογικής Πολιτικής και Δημόσιας Περιουσίας",
    economic_policy: "ΓΓ. Οικονομικής Πολιτικής"
  },
  digital_governance: {
    information_technology: "ΓΓ. Πληροφοριακών Συστημάτων Δημόσιας Διοίκησης",
    telecomunications: "ΓΓ. Τηλεπικοινωνιών και Ταχυδρομείων",
    digital_governance:
      "ΓΓ. Ψηφιακής Διακυβέρνησης και Απλούστευσης Διαδικασιών",
    main_department: "Κεντρική Υπηρεσία Υπουργείου"
  },
  migration_and_asylum: {
    immigrant_reception: "ΓΓ. Υποδοχής Αιτούντων Ασυλο",
    carrier_coordination:
      "ΕΓ. Συντονισμού Εμπλεκόμενων Φορέων Του Υπουργείου Μετανάστευσης και Ασύλου",
    child_reception: "ΕΓ. Προστασίας Ασυνόδευτων Ανηλίκων",
    main_department: "Κεντρική Υπηρεσία Υπουργείου",
    immigration_policy: "ΓΓ. Μεταναστευτικής Πολιτικής, Υποδοχής και Ασύλου"
  },
  foreign_affairs: {
    main_department: "Κεντρική Υπηρεσία Υπουργείου",
    "public-diplomacy":
      "ΓΓ. Δημόσιας Διπλωματίας, Θρησκευτικών και Προξενικών Υποθέσεων",
    emigrants: "ΓΓ. Απόδημου Ελληνισμού",
    external_economic_afairs: "ΓΓ. Διεθνών Οικονομικών Σχέσεων και Εξωστρέφειας"
  },
  development_and_investment: {
    industry: "ΓΓ. Βιομηχανίας",
    structure_programs: "ΕΓ. Διαρθρωτικών Προγραμμάτων",
    private_investments:
      "ΓΓ. Ιδιωτικών Επενδύσεων και Συμπράξεων Δημοσίου και Ιδιωτικού Τομέα (ΣΔΙΤ)",
    trade: "ΓΓ. Εμπορίου και Προστασίας Καταναλωτή",
    main_department: "Κεντρική Υπηρεσία Υπουργείου",
    espa:
      "ΓΓ. Δημοσίων Επενδύσεων και Εταιρικού Συμφώνου για το Πλαίσιο Ανάπτυξης (ΕΣΠΑ)",
    research_technology: "ΓΓ. 'Ερευνας και Τεχνολογίας"
  },
  justice: {
    main_department: "Κεντρική Υπηρεσία Υπουργείου",
    justice: "ΓΓ. Δικαιοσύνης και Ανθρώπίνων Δικαιωμάτων"
  },
  environment_and_energy: {
    waste_management: "ΓΓ. Συντονισμού Διαχείρισης Αποβλήτων",
    energy: "ΓΓ. Ενέργειας και Ορυκτών Πρώτων Υλών",
    civil_environment: "ΓΓ. Χωρικού Σχεδιασμού και Αστικού Περιβάλλοντος",
    natural_environment: "ΓΓ. Φυσικού Περιβάλλοντος και Υδάτων",
    main_department: "Κεντρική Υπηρεσία Υπουργείου"
  },
  agricultural_development_and_food: {
    rural_development: "ΓΓ. Αγροτικής Ανάπτυξης και Τροφίμων",
    main_department: "Κεντρική Υπηρεσία Υπουργείου",
    rural_policy: "ΓΓ. Αγροτικής Πολιτικής και Διαχείρισης Κοινοτικών Πόρων"
  }
}

export function sectorCode2Name(
  ministryCode?: string,
  sectorCode?: string
): string {
  if (ministryCode && sectorCode) {
    return sectorCode2NameData[ministryCode][sectorCode]
  }
  return ""
}

export const SPONSOR_AVATAR = "/images/sponsor_avatar_2.svg"
export const ADMIN_AVATAR = "/images/facilitator_2.svg"
export const MINISTRY_AVATAR = "/images/ministry_2.svg"

export const formatMonth: Record<string, string> = {
  "0": "Ιαν",
  "1": "Φεβ",
  "2": "Μαρ",
  "3": "Απρ",
  "4": "Μάη",
  "5": "Ιουν",
  "6": "Ιουλ",
  "7": "Αυγ",
  "8": "Σεπτ",
  "9": "Οκτ",
  "10": "Νοεμ",
  "11": "Δεκ"
}
export const dateTransformation = (epochMilliSeconds: number) => {
  const dateInit = new Date(0)
  dateInit.setMilliseconds(epochMilliSeconds)
  return (
    dateInit.getDay() +
    " " +
    formatMonth[dateInit.getMonth()] +
    " " +
    dateInit.getFullYear()
  )
}
