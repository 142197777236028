import React, { HTMLProps } from "react"
import { css, cx } from "emotion"
import LoadingSpinner from "components/LoadingSpinner"

export type ButtonType =
  | "secondary"
  | "primary"
  | "danger"
  | "cancel"
  | "light-danger"
export type Variant = "outlined" | "contained"

const deleteBox = css`
  background-color: transparent;
`
const primaryContained = "bg-primary text-white"
const secondaryContained = "bg-secondary text-white"
const dangerContained = "bg-danger text-white"
const lightDangerContained = cx(
  "text-default-500 border border-default-500",
  deleteBox
)

const dangerOutlined = "bg-white text-danger border border-danger"
const defaultOutlined = "bg-white text-default-500 border border-default-500"

const buttonClass = css`
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &.loading {
    cursor: wait;
    opacity: 0.75;
  }
`

function getLoaderColor(type?: ButtonType, variant?: Variant) {
  if (variant === "outlined") {
    switch (type) {
      case "danger":
        return "text-danger"
      case "primary":
        return "text-primary"
      case "cancel":
        return "text-default-500"
      default:
        return "text-default-500"
    }
  } else {
    switch (type) {
      case "primary":
        return "text-white"
      case "secondary":
        return "text-white"
      case "danger":
        return "text-white"
      default:
        return "text-white"
    }
  }
}

function chooseColors(type?: ButtonType, variant?: Variant) {
  if (variant === "outlined") {
    switch (type) {
      case "danger":
        return dangerOutlined
      case "cancel":
        return defaultOutlined
      default:
        return defaultOutlined
    }
  } else {
    switch (type) {
      case "primary":
        return primaryContained
      case "secondary":
        return secondaryContained
      case "danger":
        return dangerContained
      case "light-danger":
        return lightDangerContained
      default:
        return primaryContained
    }
  }
}

export type ButtonProps = {
  src?: string
  small?: boolean
  type?: ButtonType
  variant?: Variant
  loading?: boolean
  imgClassName?: string
} & HTMLProps<HTMLButtonElement>

function Button(props: ButtonProps) {
  const {
    src,
    small,
    type,
    variant,
    className,
    children,
    loading,
    imgClassName,
    onClick,
    ...restProps
  } = props
  const paddingClass =
    src && small
      ? "px-0 py-2 h-10 text-sm"
      : small
      ? "px-6 py-2 h-10 text-sm"
      : "px-7 py-3 h-12.5 text-default"

  const colorsClass = chooseColors(type, variant)
  return (
    <button
      onClick={loading ? undefined : onClick}
      className={cx(
        className,
        paddingClass,
        colorsClass,
        buttonClass,
        { loading },
        "rounded-md font-semibold hover:opacity-90"
      )}
      {...restProps}>
      <div className="flex items-center justify-center min-w-20">
        {src && (
          <img
            className={`${props.imgClassName} mr-1.5`}
            src={src}
            alt="button icon"
          />
        )}
        {loading ? (
          <LoadingSpinner color={getLoaderColor(type, variant)} />
        ) : (
          children
        )}
      </div>
    </button>
  )
}
export default Button
