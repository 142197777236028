import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react"
import * as R from "ramda"
import { useQuery, useMutation, queryCache } from "react-query"
import { Locale, Translations, Translate } from "./types"
import { availableLangs } from "locales"
import { loadFromStorage, saveToStorage } from "utils/storage"
import { initI18n, updateI18nKeys as _updateI18nKeys } from "./api"

const defaultTranslations = require("./defaultTranslations.json")

const STORAGE_KEY = "i18n"

interface Ii18nContext {
  lang: Locale
  translations: Translations
  t: Translate
  updateLocale: (locale: Locale) => void
  updateI18nKeys: (tr: Translations) => void
}

export const I18nContext = React.createContext<Ii18nContext>({
  lang: availableLangs[0],
  translations: {},
  t: (s: string) => s,
  updateLocale: () => {},
  updateI18nKeys: () => {}
})

interface IProps {
  children: React.ReactNode
}

function I18nProvider(props: IProps) {
  const {
    lang: initLang = availableLangs[0],
    translations: initTranslations
  } = useMemo(() => loadFromStorage(STORAGE_KEY) || {}, [])

  //TODO: set to initLang when we start using I18n
  const [lang, setLang] = useState<Locale>("el") //initLang

  const { data: fetchedTranslations = initTranslations } = useQuery(
    "i18n",
    initI18n,
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  )

  const [updateI18nKeys] = useMutation(_updateI18nKeys, {
    throwOnError: true,
    onSuccess: (updated: Translations) => {
      queryCache.setQueryData("i18n", updated)
    }
  })

  const translations = useMemo(
    () => ({ ...defaultTranslations, ...fetchedTranslations }),
    [fetchedTranslations]
  )

  const t = useCallback(
    (key: string) => R.pathOr(key, [key, lang], translations),
    [lang, translations]
  )

  useEffect(() => {
    saveToStorage(STORAGE_KEY, { lang, translations })
  }, [lang, translations])

  useEffect(() => {
    document.getElementById("root")?.setAttribute("lang", lang)
  }, [lang])

  return (
    <I18nContext.Provider
      value={{ lang, translations, t, updateLocale: setLang, updateI18nKeys }}>
      {props.children}
    </I18nContext.Provider>
  )
}
export default I18nProvider

export const useI18n = () => useContext(I18nContext)

export * from "./types"
