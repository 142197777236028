import React from "react"
import { RouteProps, Route, Redirect, useHistory } from "react-router-dom"
import { useAuth } from "providers/Auth"

function PrivateRoute(props: RouteProps) {
  const { auth } = useAuth()
  const history = useHistory()
  const cameFrom = history.location.pathname
  const to = {
    pathname: "/",
    state: { cameFrom }
  }
  return auth.access_token && !auth.firstTime ? (
    <Route {...props} />
  ) : (
    <Redirect to={to} />
  )
}
export default PrivateRoute
