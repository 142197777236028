import React, { Suspense } from "react"
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  RouteComponentProps,
  RouteProps
} from "react-router-dom"
import { ReactQueryConfigProvider } from "react-query"
import { ReactQueryDevtools } from "react-query-devtools"
import AuthProvider, { useAuth } from "providers/Auth"
import I18nProvider from "I18n"
import PrivateRoute from "components/PrivateRoute"
import lazy from "utils/lazy"

const Layout = lazy(() =>
  import(/* webpackChunkName: "LayoutMain" */ "layouts/Main")
)
const Login = lazy(() => import(/* webpackChunkName: "Login" */ "Routes/Login"))

const LandingPage = lazy(() =>
  import(/* webpackChunkName: "LandingPage" */ "Routes/LandingPage")
)

// const ResetPassword = lazy(() =>
//   import(/* webpackChunkName: "Notifications" */ "./Routes/ResetPassword")
// )

// const NewPassword = lazy(() =>
//   import(/* webpackChunkName: "Notifications" */ "./Routes/NewPassword")
// )

const LoginSuccess = lazy(() =>
  import(/* webpackChunckName: "LoginSuccess" */ "./Routes/LoginSuccess")
)

type LoginWrapperProps = {
  r: RouteComponentProps
  children: React.ReactElement
}
function LoginWrapper(props: LoginWrapperProps) {
  const { auth } = useAuth()
  const [, path = "/actions", search] = props.r.location.search.split("?")
  return auth.access_token && !auth.firstTime ? (
    <Redirect
      to={{
        pathname: path,
        search: search
      }}
    />
  ) : (
    props.children
  )
}

const queryConfig = { retry: 2 }

function App() {
  return (
    <>
      <ReactQueryConfigProvider config={queryConfig}>
        <I18nProvider>
          <AuthProvider>
            <BrowserRouter>
              <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                  <Route
                    path="/login-success"
                    render={(r) => (
                      <LoginWrapper r={r}>
                        <LoginSuccess />
                      </LoginWrapper>
                    )}></Route>
                  <Route
                    path="/login"
                    render={(r) => (
                      <LoginWrapper r={r}>
                        <Login />
                      </LoginWrapper>
                    )}
                  />
                  {/* <Route
                    path="/forgot-password"
                    render={(r) => (
                      <LoginWrapper r={r}>
                        <ResetPassword />
                      </LoginWrapper>
                    )}
                  />

                  <Route
                    path="/new-password"
                    render={(r) => (
                      <LoginWrapper r={r}>
                        <NewPassword />
                      </LoginWrapper>
                    )}
                  /> */}

                  {/*In order to make the route work we need to have the exact below. This happened to avoid changing the whole app's links and urls*/}
                  <Route exact path="/" component={LandingPage} />
                  <PrivateRoute path="/" component={Layout} />
                </Switch>
              </Suspense>
            </BrowserRouter>
          </AuthProvider>
        </I18nProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </ReactQueryConfigProvider>
    </>
  )
}

export default App
